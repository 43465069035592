import React from "react";
import styled from "styled-components";

import Breakpoints from "../constants/breakpoints";
import CircleButton from "./circleButton";

import Icon_Resume_Gray from "../images/icons/icon-resume-gray.svg";
import Icon_Resume_LightGray from "../images/icons/icon-resume-light-gray.svg";
import Icon_Resume_Hover from "../images/icons/icon-resume-hover.svg";
import Icon_Linkedin_Gray from "../images/icons/icon-linkedin-gray.svg";
import Icon_Linkedin_LightGray from "../images/icons/icon-linkedin-light-gray.svg";
import Icon_Linkedin_Hover from "../images/icons/icon-linkedin-hover.svg";
import Icon_Mail_Gray from "../images/icons/icon-mail-gray.svg";
import Icon_Mail_LightGray from "../images/icons/icon-mail-light-gray.svg";
import Icon_Mail_Hover from "../images/icons/icon-mail-hover.svg";
// import Icon_Twitter_Gray from "../images/icons/icon-twitter-gray.svg";
// import Icon_Twitter_LightGray from "../images/icons/icon-twitter-light-gray.svg";
// import Icon_Twitter_Hover from "../images/icons/icon-twitter-hover.svg";
import Icon_Dribbble_Gray from "../images/icons/icon-dribbble-gray.svg";
import Icon_Dribbble_LightGray from "../images/icons/icon-dribbble-light-gray.svg";
import Icon_Dribbble_Hover from "../images/icons/icon-dribbble-hover.svg";

import Resume from "../resume/Resume-Hermit_Yang.pdf"

const Container = styled.div`
  display: flex;
  flex-direction: row;

  & .social_icons {
    display: grid;
    grid-template-columns: repeat(3, 44px);
    grid-gap: 0 24px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Button = styled(CircleButton)`
  &.btn--resume {
    margin-right: 24px;
    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      margin: 0 auto 32px auto;
    }
  }
  
`;

const ButtonBar = ({className, lightBtn}) => {
  return (
    <Container className={className} data-sal="slide-left" data-sal-delay="600" data-sal-duration="500">
      <Button className="btn--resume"
              to={Resume}
              lightBtn={lightBtn}
              iconNormal={lightBtn ? Icon_Resume_LightGray : Icon_Resume_Gray}
              iconHover={Icon_Resume_Hover}>
        Resume
      </Button>
      <div className="social_icons">
        <Button className="btn--linkedin"
                to="http://linkedin.com/in/hermityang"
                lightBtn={lightBtn}
                iconNormal={lightBtn ? Icon_Linkedin_LightGray : Icon_Linkedin_Gray}
                iconHover={Icon_Linkedin_Hover}>
        </Button>
        <Button className="btn--mail"
                to="mailto:hermityang@gmail.com"
                lightBtn={lightBtn}
                iconNormal={lightBtn ? Icon_Mail_LightGray : Icon_Mail_Gray}
                iconHover={Icon_Mail_Hover}>
        </Button>
        {/* <Button className="btn--twitter"
                to="#"
                lightBtn={lightBtn}
                iconNormal={lightBtn ? Icon_Twitter_LightGray : Icon_Twitter_Gray}
                iconHover={Icon_Twitter_Hover}>
        </Button> */}
        <Button className="btn--dribbble"
                to="https://dribbble.com/hermityang"
                lightBtn={lightBtn}
                iconNormal={lightBtn ? Icon_Dribbble_LightGray : Icon_Dribbble_Gray}
                iconHover={Icon_Dribbble_Hover}>
        </Button>
      </div>
    </Container>
  );
};

export default ButtonBar;
