import React from "react";
import styled from "styled-components";
import Colors from "../../constants/colors";
import Icon_Email from "../../images/icons/icon-email-contact.png";
import Icon_Email_hover from "../../images/icons/icon-email-contact-hover.png";
import Icon_Location from "../../images/icons/icon-location-contact.png";
import Icon_Location_hover from "../../images/icons/icon-location-contact-hover.png";
import Icon_Phone from "../../images/icons/icon-phone-contact.png";
import Icon_Phone_hover from "../../images/icons/icon-phone-contact-hover.png";

const getIcon = (methodType, hover) => {
  switch(methodType) {
    case 'Email':
      return (hover ? `${Icon_Email_hover}` : `${Icon_Email}`);
    case 'Location':
      return (hover ? `${Icon_Location_hover}` : `${Icon_Location}`);
    case 'Phone':
      return (hover ? `${Icon_Phone_hover}` : `${Icon_Phone}`);
    default:
      return ;
  }
}

const Container = styled.a`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  background-image: url(${props => getIcon(props.icon)});
  background-position: 0 0;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: all 0.25s ease-in-out;

  &:not(:last-child) {
    margin-right: 48px;
  }

  &:hover {
    background-image: url(${props => getIcon(props.icon, true)});

    .contact_method__type {
      color: ${Colors.Text.contact_method_title_hover};
    }
    .contact_method__value {
      color: ${Colors.Text.contact_method_value_hover};
    }
  }
`;

const MethodType = styled.h5`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin: 0;
  color: ${Colors.Text.contact_method_title};
`;

const MethodValue = styled.span`
  font-size: 1.0625rem;
  line-height: 1.625rem;
  color: ${Colors.Text.contact_method_value};
`;

const contactMethod = (props) => {
  return (
    <Container href={props.to} icon={props.methodType} className={props.className} target="_blank">
      <MethodType className="contact_method__type">{props.methodType}</MethodType>
      <MethodValue className="contact_method__value">{props.methodValue}</MethodValue>
    </Container>
  );
}

export default contactMethod;