import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image from 'gatsby-image';

import { Link } from "gatsby";
import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

const Container = styled.li`
  margin: 0;
`;

const Card = styled(Link)`
  display: block;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(54, 54, 54, 0.05), 0 2px 40px 0 rgba(54, 54, 54, 0.07);
  position: relative;
  overflow: hidden;
  width: 100%;
  
  transition: all 0.2s ease-in-out;

  &:hover, &:active, &:focus {
    box-shadow: 0px 2px 2px rgba(54,54,54,0.05), 0px 4px 4px rgba(54,54,54,0.05), 0px 8px 8px rgba(54,54,54,0.05), 0px 16px 16px rgba(54,54,54,0.05),  0px 32px 32px rgba(54,54,54,0.05);
    transform: translateY(-4px);
    
    .project__info {
      top: 65%;

      @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
        top: 55%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
        top: 65%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        top: 45%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        top: 70%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        top: 58%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
        top: 52%;
      }
    }
    .project__description {
      opacity: 1;
    }
    .project__thumbnail {
      transform: scale(1.1);

      &::before {
        opacity: 0.9;
        height: 42%;

        @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
          height: 51%;
        }

        @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
          height: 42%;
        }

        @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
          height: 62%;
        }

        @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
          height: 36%;
        }

        @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
          height: 49%;
        }

        @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
          height: 56%;
        }
      }
    }
    .project__metadata {
      opacity: 1;
    }
  }
`;

const Thumbnail = styled(Image)`
  background-color: #fff;
  transform: scale(1.01);
  transition: all 0.5s ease-in-out;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    position: absolute;
    bottom: 0;
    background: ${props => props.hoverColor || Colors.Basic.gray_31};
    opacity: 0;
    z-index: 2;
    transition: all 0.3s ease-in-out;

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      height: 36%;
      opacity: 0.75;
    }

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      height: 49%;
    }

    @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
      height: 56%;
    }
  }
`;

const Info = styled.div`
  position: absolute;
  padding-left: 10%;
  padding-right: 10%;
  top: 79%;
  z-index: 10;
  transition: all 0.3s ease-in-out;

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    top: 70%;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    top: 58%;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
    top: 52%;
  }
`;

const Title = styled.h3`
  color: ${props => props.titleColor || Colors.Text.portfolio_project_title};
  font-family: ${Fonts.Font_family.title_condensed};
  font-size: 2.5rem;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  white-space: nowrap;
  margin-bottom: 32px;
  position: relative;
  transition: all 0.2s ease-in-out;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
    font-size: 2.3rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    font-size: 2.25rem;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 2px;
    background-color: ${props => props.underlineColor || Colors.Basic.orange_01};
    position: absolute;
    bottom: -8px;
    left: 0;
  }

`;

const Description = styled.p`
  color: ${props => props.titleColor || Colors.Text.portfolio_project_description};
  font-size: 0.9375rem;
  opacity: 0;
  transition: all 0.3s ease-in-out 0.2s;
  margin-bottom: 24px;

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    opacity: 1;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
    font-size: 0.9375rem;
  }
`;

const MetaData = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  transition: all 0.3s ease-in-out 0.2s;

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    opacity: 1;
  }
`;

const MetaDataContainer = styled.div`
  font-family: ${Fonts.Font_family.title_condensed};
  letter-spacing: 1px;

  span {
    display: inline-block;
    color: ${props => props.titleColor || Colors.Text.default};

    &:first-child {
      color: ${props => props.titleColor ? Colors.Basic.gray_75 : Colors.Text.project_meta_title};
      margin-right: 8px;
    }
  }
`;

const Project = ({metadata}) => {
  const {title, shortDescription, slug, thumbnail, hoverColor, titleColor, year} = metadata;
  const thumbnailImage = thumbnail.fluid;
  
  return (
    <Container data-sal="slide-up" data-sal-duration="800">
      <Card to={`/portfolio/${slug}`} >
        <Info className="project__info">
          <Title className="project__title" titleColor={titleColor}>{title}</Title>
          <Description className="project__description" titleColor={titleColor}>{shortDescription}</Description>
          <MetaData className="project__metadata">
            <MetaDataContainer titleColor={titleColor}>
              <span>Year</span>
              <span>{year}</span>
            </MetaDataContainer>
          </MetaData>
        </Info>
        <Thumbnail className="project__thumbnail" fluid={thumbnailImage} hoverColor={hoverColor}/>
      </Card>
    </Container>
  )
}

Project.propTypes = {
  metadata: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    thumbnail: PropTypes.object.isRequired
  })
};

export default Project;
