import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {slide as Menu} from "react-burger-menu";

import Navigation from "./navigation";
import SiteLogo from "./siteLogo";
import Colors from "../constants/colors";
import Breakpoints from "../constants/breakpoints";

const menuLinks = [
  {
    "name": "Home",
    "to": "#home"
  },
  {
    "name": "About",
    "to": "#about"
  },
  {
    "name": "Portolio",
    "to": "#portfolio"
  },
  {
    "name": "Contact",
    "to": "#contact"
  },
];

const TopBar = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: fixed;
  z-index: 100;
  background-color: ${Colors.Background.main_nav};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &.onTop {
    background-color: ${Colors.Background.main_nav_on_top};
    box-shadow: none;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  max-width: 90rem;
  padding-right: 32px;

  /* Hamburger menu */
  .bm-burger-button {
    position: absolute;
    width: 24px;
    height: 17px;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    align-self: center;
    display: none;

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      display: block;
    }
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: ${Colors.Icon.default};
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: ${Colors.Icon.default_hover};
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    
    &:hover .bm-cross {
      background: ${Colors.Icon.white_hover};
    }
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: ${Colors.Icon.white};

  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: ${Colors.Background.mobile_nav};
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    /* padding: 0.8em; */
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    text-decoration: none;
    color: ${Colors.Text.mobile_nav_item};
    padding: 12px 24px;
    border-radius: 24px;

    &:hover, &:focus {
      color: ${Colors.Text.mobile_nav_item_hover};
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const MobileMenu = styled(Menu)`
  display: none;
  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    display: block;
  }
`;

const MainNav = styled(Navigation)`
  margin-left: auto;
  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    display: none;
  }
`;

const Header = ({ siteTitle }) => {
  const [isTop, setCheckTop] = useState(true);
  const [showLogo, setLogo] = useState(false);
  const [menuOpen, setMenuState] = useState(false);

  function handleStateChange (state) {
    setMenuState(state.isOpen); 
  }
  function closeMenu () {
    setMenuState(false);
  }

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const checkPosition = window.scrollY;
      const checkTop = checkPosition < 1;
      const checkLogo = checkPosition > 200;

      if (checkTop !== isTop) {
        setCheckTop(checkTop);
      }
      if (checkLogo !== showLogo) {
        setLogo(checkLogo);
      }
    });
    
  });

  return (
    <TopBar className = {isTop ? "onTop" : ""}>
      <MenuContainer>
        <MobileMenu 
          disableAutoFocus 
          pageWrapId = {"content-container"}
          isOpen={menuOpen}
          onStateChange={(state) => handleStateChange(state)}
        >
          {menuLinks.map(link => (
            <a
              key={link.name}
              className="menu-item"
              href={link.to}
              onClick={() => closeMenu()}
            >
              {link.name}
            </a>
          ))}
        </MobileMenu>
        <SiteLogo className={showLogo ? "" : "hidden"}></SiteLogo>
        
        <MainNav />
      </MenuContainer>
    </TopBar>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
