import React from "react";
import styled from "styled-components";

import CircleButton from "../circleButton";
import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

import bgImage from "../../images/home/bg-home_components.png";
import logo from "../../images/logo/logo-160x160.svg";
import Icon_Linkedin_LightGray from "../../images/icons/icon-linkedin-light-gray.svg";
import Icon_Linkedin_Hover from "../../images/icons/icon-linkedin-hover.svg";
import Icon_Mail_LightGray from "../../images/icons/icon-mail-light-gray.svg";
import Icon_Mail_Hover from "../../images/icons/icon-mail-hover.svg";
import Icon_ScrollIndicator from "../../images/icons/icon-arrow-down.svg";
import Icon_Resume_LightGray from "../../images/icons/icon-resume-light-gray.svg";
import Icon_Resume_Hover from "../../images/icons/icon-resume-hover.svg";

import Resume from "../../resume/Resume-Hermit_Yang.pdf"

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 300px auto;
  grid-template-rows: 80px auto min-content minmax(16px, auto) 76px 40px;
  height: 100vh;
  background-position: 50% 80px;
  background-color: ${Colors.Background.home};
  background-image: url(${bgImage});
  background-size: 1330px 910px;
  background-repeat: repeat;
  overflow: hidden;

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    justify-items: center;
  }

  @media only screen and (max-height: ${Breakpoints.Height.home_minimun}) {
    height: auto;
  }
`;

const Card = styled.div`
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 32px 24px;
  background: ${Colors.Background.home_card};
  border-radius: 10px;

  .card {
    &__title {
      text-transform: uppercase;
      color: ${Colors.Text.home_card_title};
      font-family: ${Fonts.Font_family.title_condensed};
      font-weight: normal;
      font-size: 3rem;
      line-height: 3rem;
      margin-bottom: 0;
    }
  
    &__subtitle {
      text-transform: uppercase;
      color: ${Colors.Text.home_card_subtitle};
      font-family: ${Fonts.Font_family.title_condensed};
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.4rem;
      letter-spacing: 0.5px;
      margin-bottom: 0;
    }

    &__separator {
      width: 24px;
      height: 1px;
      margin: 24px auto;
      border-bottom: 1px solid ${Colors.Separator.home_card};
    }

    &__description {
      color: ${Colors.Text.home_card_description};
      font-size: 0.9rem;
      text-align: center;
      margin-bottom: 0;
    }

    &__social {
      display: flex;
    }
  }
`;

const Logo = styled.img`
  width: 160px;
  height: 160px;
  margin-bottom: 1.5rem;
`;

const ScrollIndicator = styled.div`
  grid-column: 2 / 3;
  grid-row: 5 / 6;
  align-self: center;
  justify-self: center;

  height: 48px;
  padding: 16px 20px 16px 44px;
  border-radius: 24px;
  background: url(${Icon_ScrollIndicator}) 18px 50% no-repeat;
  background-color: rgba(255, 255, 255, 0.7);
  font-family: 'Bebas Neue';
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 1px;
  color: ${Colors.Text.home_scrolldown_text};
`;

const Button = styled(CircleButton)`
  margin: 0 16px;
`;

const Home = () => {
  
  return (
    <Container name="home" id="home">
      <Card data-sal="fade" data-sal-delay="200" data-sal-duration="350">
        <Logo src={logo} alt='logo'/>
        <h1 className="card__title">Hermit Yang</h1>
        <h2 className="card__subtitle">UI/UX Designer</h2>
        <div className="card__separator"></div>
        <p className="card__description">Hi, I'm Hermit, a UI/UX Designer based in San Francisco Bay Area.</p>
        <div className="card__separator"></div>
        <div className="card__social">
          <Button className="btn--linkedin"
                  to="http://linkedin.com/in/hermityang"
                  lightBtn
                  iconNormal={Icon_Linkedin_LightGray}
                  iconHover={Icon_Linkedin_Hover}>
          </Button>
          <Button className="btn--mail"
                  to="mailto:hermityang@gmail.com"
                  lightBtn
                  iconNormal={Icon_Mail_LightGray}
                  iconHover={Icon_Mail_Hover}>
          </Button>
          <Button className="btn--resume"
                  to={Resume}
                  lightBtn
                  iconNormal={Icon_Resume_LightGray}
                  iconHover={Icon_Resume_Hover}>
          </Button>
        </div>
      </Card>
      <ScrollIndicator className="scrolldown-indicator">
        Scroll to explore
      </ScrollIndicator>
    </Container>
  );
}

export default Home;