import React from "react";
import styled from "styled-components";
import Colors from "../constants/colors"

const Container = styled.a`
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.2rem;
  letter-spacing: 0.2px;
  color: ${props => props.lightBtn ? Colors.Text.light : Colors.Text.default};
  text-decoration: none;
  width: ${props => props.children ? 'auto' : '44px'};
  height: 44px;
  border-radius: 22px;
  padding: ${props => props.children ? '11px 20px 11px 48px' : '0'};
  border: 1px solid ${props => props.lightBtn ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.1)'} ;
  transition: all 0.4s ease-in-out;
  background-image: url(${props => props.iconNormal});
  background-position: ${props => props.children ? '6px 50%' : '50% 50%'};
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover{
    border: 1px solid ${Colors.Basic.orange_01};
    color: ${props => props.lightBtn ? Colors.Text.about_light_button_hover : Colors.Text.about_button_hover};
    background-image: url(${props => props.iconHover});
  }
`;

const Button = props => {
  return (
    <Container className={props.className} href={props.to} lightBtn={props.lightBtn} iconNormal={props.iconNormal} iconHover={props.iconHover} target="_blank">
      {props.children}
    </Container>
  );
}

export default Button;