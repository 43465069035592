import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from 'react-scroll'

import Colors from "../constants/colors"

const getMainNav = graphql`
  query {
    site {
      siteMetadata {
        mainNav {
          name
          to
        }
      }
    }
  }
`;

const NavContainer = styled.nav`
  z-index: 99;
  grid-column: 2 / 3;
  align-self: center;

  ul {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(4, max-content); 
    justify-content: end;
    margin: 0;
  }

  li {
    list-style: none;
    margin:0;
  }
`;

const NavItem = styled(Link)`
  font-size: 1rem;
  font-weight: 500;
  padding: 16px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: ${Colors.Text.nav_item};

  &:hover, &:focus {
    color: ${Colors.Text.nav_item_hover};
  }

  &.active {
    color: ${Colors.Text.nav_item_active};
  }

  /* Underline indicator */
  &::after {
    content: "";
    position: absolute;
    z-index: 10;
    left: 1rem;
    width: 0;
    height: 2px;
    bottom: 8px;
    background-color: ${Colors.Basic.orange_01};

    transition-property: width;
    transition-timing-function: ease-out;
    transition-duration: 0.4s;
  }

  &.active::after {
    left: 1rem;
    right: auto;
    width: 16px;
  }

  &:hover::after, &:focus::after {
    width: 16px;
    
  }
`;

const Navigation = ({className}) => {
  const data = useStaticQuery(getMainNav);
  const menuLinks = data.site.siteMetadata.mainNav;

  return (
		<NavContainer className={className}>
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <NavItem 
              to={link.to}
              activeClass="active"
              spy={true}
              smooth={true}
              duration={800}
              isDynamic={true}
            >
              {link.name}
            </NavItem>
          </li>
        ))}
      </ul>
		</NavContainer>
	)
}

export default Navigation;