import React from "react"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"

import Home from "../components/home/home"
import About from "../components/about/about"
import Portfolio from "../components/portfolio/portfolio"
import Contact from "../components/contact/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Hermit Yang" />
    <Home />
    <About />
    <Portfolio />
    <Contact />
  </Layout>
)

export default IndexPage
