import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import SectionTitle from "../sectionTitle";
import ContactMethod from "./contactMethod";
import ButtonBar from "../buttonBar";

import Colors from "../../constants/colors";
import Breakpoints from "../../constants/breakpoints";

const Container = styled.div`
  background-color: ${Colors.Background.contact};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 12rem 2rem 12rem;
  @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }  
`;

const ContactSectionTitle = styled(SectionTitle)`
  color: ${Colors.Text.contact_section_title};
  text-align: center;
  position: relative;
  margin-bottom: 72px;

  &::after {
    content: "";
    background-color: ${Colors.Separator.contact};
    display: block;
    width: 32px;
    height: 1px;
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translate(-50%);
  }
`;

const Avatar = styled(BackgroundImage)`
  width: 7.5rem;
  height: 7.5rem;
  min-width: 7.5rem;
  min-height: 7.5rem;
  border-radius: 50%;
  border: 2px solid ${Colors.Basic.white};
  background-color: ${Colors.Basic.white};
  background-repeat: no-repeat;
  background-position: 45% -5%;
  background-size: 200px;
  overflow: hidden;
`;

const Subtitle = styled.h4`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: ${Colors.Text.contact_subtitle};
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  position: relative;

  &::after {
    content: "";
    background-color: ${Colors.Separator.contact};
    display: block;
    width: 16px;
    height: 1px;
    position: absolute;
    bottom: -2.5rem;
    left: 50%;
    transform: translate(-50%);
  }
`;

const ContactMethods = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  position: relative;

  &::after {
    content: "";
    background-color: ${Colors.Separator.contact};
    display: block;
    width: 16px;
    height: 1px;
    position: absolute;
    bottom: -4rem;
    left: 50%;
    transform: translate(-50%);
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    flex-direction: column;
    
    a:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

const ContactButtonBar = styled(ButtonBar)`
  margin-top: 72px;
`;

const getBackgroundImage = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "images/about/hermit.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const Contact = () => {
  const data = useStaticQuery(getBackgroundImage);

  return (
    <Container name="contact" id="contact">
      <ContactSectionTitle>Contact</ContactSectionTitle>
      <Avatar fluid={data.backgroundImage.childImageSharp.fluid} data-sal="slide-up" data-sal-delay="250" data-sal-duration="800"></Avatar>
      <Subtitle data-sal="fade" data-sal-delay="500" data-sal-duration="800">Where to find me</Subtitle>
      <ContactMethods data-sal="slide-up" data-sal-delay="350" data-sal-duration="800">
        <ContactMethod methodType="Email" methodValue="hermityang@gmail.com" to="mailto:hermityang@gmail.com"/>
        <ContactMethod methodType="Location" methodValue="San Francisco Bay Area" to="https://www.google.com/maps/place/San+Francisco,+CA"/>
        <ContactMethod methodType="Phone" methodValue="(415) 935-3009" to="tel:4159353009"/>
      </ContactMethods>
      <ContactButtonBar lightBtn/>
    </Container>
  );
}

export default Contact;
