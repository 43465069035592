import React from 'react';
import styled from 'styled-components';

import Fonts from '../constants/fonts';
import Colors from '../constants/colors';

const Title = styled.h3`
	font-family: ${Fonts.Font_family.title_condensed};
	font-size: 4.5rem;
	line-height: 1;
	letter-spacing: 1px;
	color: ${Colors.Text.section_title};
`;

const SectionTitle = ({className, children}) => {
	return (
		<Title className={className} data-sal="fade" data-sal-duration="800">
			{children}
		</Title>
	);
}

export default SectionTitle;
