import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SectionTitle from "../sectionTitle";
import Colors from "../../constants/colors";
import Breakpoints from "../../constants/breakpoints";

import ProjectCard from "./projectCard";

const Container = styled.section`
  background-color: ${Colors.Background.portfolio};
  display: grid;
  grid-template-columns: minmax(2rem, auto) [content-start] 1fr [content-end] minmax(2rem, auto);
  padding: 10rem 0 15rem;

  @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
    padding-top: 7rem;
    padding-bottom: 7rem;
    grid-template-columns: minmax(1.5rem, auto) [content-start] 1fr [content-end] minmax(1.5rem, auto);
  }  
`;

const PortfolioSectionTitle = styled(SectionTitle)`
  grid-row: 1 / 2;
  grid-column: content-start / content-end;
  text-align: center;
  position: relative;
  margin-bottom: 64px;

  &::after {
    content: "";
    background-color: ${Colors.Separator.portfolio_title};
    display: block;
    width: 32px;
    height: 1px;
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translate(-50%);
  }
`;

const Projects = styled.ul`
  grid-row: 2 / 3;
  grid-column: content-start / content-end;

  display: grid;
  grid-template-columns: repeat(3, minmax(18rem, 27rem));
  grid-gap: 2.5rem;
  justify-content: center;

  list-style: none;
  margin: 0;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-template-columns: repeat(2, minmax(15rem, 24rem));
    grid-gap: 1.5rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: ${Breakpoints.Width.mobile_medium}) {
    grid-gap: 1.2rem;
  }
`;

// const More = styled.button`
//   grid-row: 3 / 4;
//   grid-column: content-start / content-end;
//   justify-self: center;
//   font-size: 1rem;
//   line-height: 1rem;
//   letter-spacing: 0.2px;
//   color: ${Colors.Text.default};
//   text-decoration: none;
//   width: 180px;
//   padding: 11px 38px 11px 22px;
//   margin-top: 60px;
//   border-radius: 22px;
//   border: 1px solid rgba(0, 0, 0, 0.1);
//   background: url('/images/icons/icon-caret-right.png') right 22px top 50% no-repeat;
//   cursor: pointer;
//   transition: all 0.4s ease-in-out;
//   position: relative;

//   &:hover{
//     border: 1px solid ${Colors.Basic.orange_01};
//     color: ${Colors.Text.about_button_hover};
//   }

//   &::after {
//     content: "";
//     background-color: ${Colors.Separator.portfolio_title};
//     display: block;
//     width: 32px;
//     height: 1px;
//     position: absolute;
//     top: -24px;
//     left: 50%;
//     transform: translate(-50%);
//   }
// `;

const getProjects = graphql`
  query {
    projects:allContentfulProject {
      edges {
        node {
          title
          description {
            description
          }
          shortDescription
          slug
          order
          year
          role
          contentful_id
          thumbnail {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          hoverColor
          titleColor
        }
      }
    }
  }
`;

const Portfolio = () => {
  const data = useStaticQuery(getProjects);
  const projects = data.projects.edges;
  
  return (
    <Container name="portfolio" id="portfolio">
      <PortfolioSectionTitle>Portfolio</PortfolioSectionTitle>
      <Projects>
        {projects
          .sort((a, b) => a.node.order-b.node.order)
          .map(({node}) => {
            return <ProjectCard key={node.order} metadata={node} />
          })
        }
      </Projects>
      {/* <More>More Projects</More> */}
    </Container>
  );
};



export default Portfolio;
