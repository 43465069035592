import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SectionTitle from "../sectionTitle";
import ButtonBar from "../buttonBar";
import Skill from "./skill";

import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

import HermitImage from "../../images/about/hermit.png"

const Container = styled.div`
  background-color: ${Colors.Background.about};
  display: grid;
  grid-template-columns: minmax(4rem, 1fr) [content-start] repeat(3, 20.5rem) minmax(14.5rem, 25rem) [content-end] minmax(4rem, 1fr);
  padding: 12rem 0 5.5rem;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_medium}) {
    grid-template-columns: minmax(3rem, 1fr) [content-start] repeat(3, 17.5rem) minmax(14.5rem, 25rem) [content-end] minmax(3rem, 1fr);
  }

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-template-columns: minmax(3rem, 1fr) [content-start] repeat(2, minmax(min-content, 22rem))  [content-end] minmax(3rem, 1fr);
    padding: 8.5rem 0 5rem;
  }
  
  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-template-columns: minmax(1.5rem, 1fr) [content-start] repeat(2, minmax(min-content, 22rem))  [content-end] minmax(1.5rem, 1fr);
    padding: 8.5rem 0 5rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.about_small}) {
    display: flex;
    flex-direction: column;
    padding: 7rem 2rem 4.5rem;
  }

`;

const AboutSectionTitle = styled(SectionTitle)`
  grid-row: 1 / 2;
  grid-column: content-start / content-end;
  margin-bottom: 16px;
  order: 1;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const Description = styled.p`
  grid-row: 2 / 3;
  grid-column: 2 / 4;
  color: ${Colors.Text.default};
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.2px;
  margin-bottom: 80px;
  order: 2;

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    margin-left: 20px;
    margin-right: 20px;
    grid-column: 2 / 3;
  }

  @media only screen and (max-width: ${Breakpoints.Width.about_small}) {
    margin-bottom: 40px;
  }
`;

const SkillSet = styled.div`
  grid-row: 3 / 4;
  margin-right: 40px;
  padding-top: 36px;
  border-top: 1px solid ${Colors.Separator.about_skillset_top};

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    margin: 0 20px 40px 20px;
  }

  .skillset__title {
    font-family: ${Fonts.Font_family.title_condensed};
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 56px;
    color: ${Colors.Text.default};
    position: relative;

    &::after {
      content: "";
      background-color: ${Colors.Basic.orange_01};
      display: block;
      width: 24px;
      height: 3px;
      position: absolute;
      bottom: -20px;
    }
  }

  .skillset__description {
    color: ${Colors.Text.default};
    font-size:  0.9375rem;
    margin-bottom: 48px;
    position: relative;

    &::after {
      content: "";
      background-color: ${Colors.Separator.about_skillset};
      display: block;
      width: 8px;
      height: 2px;
      position: absolute;
      bottom: -28px;
    }
  }

  .skillset__list {
    list-style: none;
    margin: 0;
  }

  &.skillset--design {
    grid-column: 2 / 3;
    order: 4;
  }
  &.skillset--interaction {
    grid-column: 3 / 4;
    order: 5;
    @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
      grid-row: 4 / 5;
      grid-column: 2 / 3;
    }
  }
  &.skillset--coding {
    grid-column: 4 / 5;
    order: 6;
    @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
      grid-row: 4 / 5;
      grid-column: 3 / 4;
    }
  }
`;

const PortraitImage = styled.div`
  grid-row: 1 / 6;
  grid-column: 5 / 6;
  min-width: 400px;
  background-image: url(${HermitImage});
  background-size: contain;
  background-position: 0 0;
  background-repeat: no-repeat;
  
  @media only screen and (max-width: ${Breakpoints.Width.about_medium}) {
    grid-column: 5 / 7;
  }

  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-row: 1 / 4;
    grid-column: 3 / 5;
    background-position: left 10% bottom 0;
    margin: 0 20px 40px;
  }

  @media only screen and (max-width: ${Breakpoints.Width.about_small}) {
    background-position: 50% 0;
    margin: 0 0 40px;
    min-width: auto;
    width: 100%;
    height: 400px;
    align-self: center;
    order: 3;
  }

`;

const AboutButtonBar = styled(ButtonBar)`
  grid-row: 5 / 6;
  grid-column: content-start / content-end;
  margin-top: 100px;
  margin-bottom: 80px;
  order: 7;
  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    margin-top: 56px;
    margin-bottom: 40px;
  }
`;

const getData = graphql`
  query {
    skillSets: allContentfulSkillSet {
      edges { 
        node {
          contentful_id
          title
          description {
            description
          }
          className
        }
      }
    }
    skills: allContentfulSkill {
      edges {
        node {
          order
          contentful_id
          title
          skillset {
            title
          }
          description {
            description
          }
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
              src
            }
          }
        }
      }
    }
  }
`;

const About = () => {
  const data = useStaticQuery(getData);
  const skillSets = data.skillSets.edges;
  const skills = data.skills.edges;

  return (
    <Container name="about" id="about">
      <AboutSectionTitle>About</AboutSectionTitle>
      <Description data-sal="slide-up" data-sal-delay="200" data-sal-duration="800">
        Hi, I’m Hermit Yang, a Designer based in the San Francisco Bay Area. 
        Over 10 years of experience in designing and developing a variety of products.
        Enthusiastic about exploring the experience and making things happen.  :-)
      </Description>
      {skillSets.map((category, index) => {
        return (
          <SkillSet className={category.node.className} key={index} data-sal="slide-up" data-sal-delay="300" data-sal-duration="1500">
            <h4 className="skillset__title">{category.node.title}</h4>
            <p className="skillset__description">{category.node.description.description}</p>
            <ul className="skillset__list">
              {skills
                .filter((skill)=>{
                  return (skill.node.skillset.title === category.node.title);
                })
                .sort((a, b) => a.node.order-b.node.order)
                .map((skill, index) => {
                  return (
                    <Skill key={index} metadata={skill.node}>
                      {skill.node.title}
                    </Skill>
                  );
                })
              }
            </ul>
          </SkillSet>
        );
      })}

      <PortraitImage data-sal="slide-left" data-sal-delay="500" data-sal-duration="2000"></PortraitImage>
      <AboutButtonBar />
    </Container>
  );
}

export default About;