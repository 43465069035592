import React from "react";
import styled from "styled-components";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";

import PlusIcon from "../../images/icons/icon-plus-orange.svg";
import PlusIconHover from "../../images/icons/icon-arrow-right-blue.svg";

const SkillTippy = styled(Tippy)`
  background: ${Colors.Basic.gray_94};
  color: ${Colors.Text.default};
  box-shadow: 0 0 20px 4px rgba(154,161,177,.15),0 4px 80px -8px rgba(36,40,47,.25),0 4px 4px -2px rgba(91,94,105,.15), 0 0 40px 8px rgba(0,0,0,0.08);
  border-radius: 6px;
  padding: 1rem 1rem 1rem 0;
  width: 500px;
  background-image: url(${props => props.tipThumbnail});
  background-position: 20px 50%;
  background-size: 120px auto;
  background-repeat: no-repeat;

  /* Styling the arrow for different placements */
  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: ${Colors.Basic.gray_94};
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: ${Colors.Basic.gray_94};
  }

  .tip__container {
    padding-left: 154px;
  }

  .tip__title {
    font-family: ${Fonts.Font_family.title_condensed};
    font-size: 2rem;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
  }

  .tip__description {
    margin-bottom: 0;
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }
`;

const SkillText = styled.span`
  display: inline-block;
  padding-left: 16px;
  color: ${Colors.Text.default};
  letter-spacing: 0.2px;
  background-image: url(${PlusIcon});
  background-position: 0 50%;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-image: url(${PlusIconHover});
    color: ${Colors.Text.emphasized};
    font-weight: 500;
    background-position: 1px 50%;
    background-repeat: no-repeat;
    padding-left: 20px;
  }
`;

const Skill = ({metadata, children}) => {

  const {title, description, image} = metadata;
  const skillImage = image.fluid.src;

  return (
    <li>
      <SkillTippy 
        tipThumbnail={skillImage}
        content={
          <div className="tip__container">
            <h5 className="tip__title">{title}</h5>
            <p className="tip__description">{description.description}</p>
          </div>
        }  
        maxWidth="calc(100vw - 32px)"
        duration={[400, 300]}
      >
        <SkillText className="skill__text">
          {children}
        </SkillText>
      </SkillTippy>
    </li>
  );
}

export default Skill;